import { useQuery } from "@apollo/client";
import { UNLOCKED_CLASSES_QUERY } from "graphql/queries";
import { useMemo } from "react";

export function useUnlockedClasses() {
  const { data, loading, error } = useQuery(UNLOCKED_CLASSES_QUERY, {
    errorPolicy: "all",
  });

  const unlockedClasses = useMemo(() => {
    if (data?.me?.unlockedClasses) {
      return data.me.unlockedClasses.filter((c: any) => c);
    }
    return [];
  }, [data]);

  const hasUnavailableClasses = !!error;

  return { unlockedClasses, hasUnavailableClasses, loading, error };
}
